import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import { isPimUnitSystem } from "../../../utils"
import SaunaCalculator from "../../molecules/SaunaCalculator"
import ProductFilter from "../../organisms/product/ProductFilter"
import DatoImage from "../DatoImage"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"
import * as styles from "./DatoSaunaCalculatorBlock.module.scss"

const DatoSaunaCalculatorBlock = (props: Queries.DatoSaunaCalculatorBlockFragment) => {
  //console.log("Rendering DatoSaunaCalculatorBlock", props)

  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("saunaCalculator", locale)

  const [result, setResult] = React.useState(0)
  const unit = props.units === "imperial" ? "ft" : "m"
  const powerUnit = "kW"
  const powerResult = (result * 0.7).toPrecision(2)

  const productCategories = props.showProductsFromCategory
    ? props.showProductsFromCategory.split(/[,\s]+/)
    : ["02_sauna_electric_heaters", "02_sauna_wood_heaters"]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputPart}>
          <div className="layout-container">
            <h2>{props.title}</h2>
            <p>{props.introText}</p>
            <SaunaCalculator
              units={isPimUnitSystem(props.units) ? props.units : undefined}
              onResult={(res: number) => {
                setResult(res)
              }}
            />
          </div>
        </div>
        <div className={styles.resultPart}>
          <DatoImage {...props.image} className={styles.bgImage} alt={props.image?.alt || ""} />
          <div className={`layout-container ${styles.resultContent}`}>
            <h3>{t("resultText")}</h3>
            <p className={styles.resultValue}>
              {result} {unit}³
            </p>
            {!!result && props.showPowerResult && (
              <>
                <h3>{t("resultTextPower", "Heater power should be")}</h3>
                <p className={styles.resultValue}>
                  {powerResult} {powerUnit}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {props.helpText && (
        <div className="layout-container">
          <DatoStructuredTextBlock data={props.helpText} shiftHeadings={2} />
        </div>
      )}
      {!!result && (
        <div className="layout-container">
          <ProductFilter
            query={`${productCategories
              .map(c => `categories:${c}`)
              .join(",")},room_max_volume:gte:${result},room_min_volume:lte:${result}`}
          />
        </div>
      )}
    </>
  )
}

export default DatoSaunaCalculatorBlock

export const query = graphql`
  fragment DatoSaunaCalculatorBlock on DatoCmsSaunaCalculatorBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    image {
      ...DatoImageDefault
    }
    introText
    title
    units

    showProductsFromCategory

    showPowerResult

    helpText {
      value
    }
  }
`
